import React, { useState, useContext, useEffect } from 'react'
import { useAxiosWithToken } from '../../../hooks'
import api from '../../../api'
import Select from '../../../components/common/inputs/Select'
import Textbox from '../../../components/common/inputs/Textbox'
import Modal from '../../../components/common/Modal'
import ComponentLoading from '../../../components/common/componentLoading'
import { Pagination } from "antd";
import { svgFileUrl } from '../../../config'
import TableRow from "./components/TableRow";
import AddEditModal from './components/AddEditModal'
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
    // ─── Global Variable ────────────────────────────────────────────────────────────

    // ─── States ─────────────────────────────────────────────────────────────────────
    const [data, setData] = useState([])
    const [selectedCategory, setSelectedCategory] = useState("")
    const [searchValue, setSearchValue] = useState("")
    const [currentPage, setCurrentPage] = useState(1);
    const [totalElements, setTotalElements] = useState(0);
    const [rowData, setRowData] = useState(null)
    const [modal, setModal] = useState(false)
    const [loading, setLoading] = useState(false)
    // ─── Life Cycle ─────────────────────────────────────────────────────────────────

    // ─── Functions ──────────────────────────────────────────────────────────────────
    const getData = (currentPage) => {
        setLoading(true)
        useAxiosWithToken.get(api.foodsNutrition.searchFoodsNutrition + `?pageNo=${currentPage - 1}&pageSize=10&name=${searchValue}&categoryName=` + selectedCategory).then(res => {
            setData(res.data?.elements)
            setTotalElements(res.data.totalElements);
            setLoading(false)
        }).catch(err => {
            setLoading(false)
        })
    }
    useEffect(() => {
        getData(currentPage)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCategory, searchValue, currentPage])
    useEffect(() => {
        setSelectedCategory("")
    }, [searchValue])

    //
    const closeModal = () => {
        setModal(false)
        getData(currentPage)
        setSearchValue("")
    }
    const showEditModal = (rowData) => {
        setRowData(rowData)
        setModal(true)
    }
    const showAddModal = (rowData) => {
        setRowData(null)
        setModal(true)
    }
    // ──────────────────────────────────────────────────── I ──────────
    //   :::::: R E N D E R : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────
    //
    return (
        <>
            <section className='w-full bg-white min-h-10 rounded-lg p-9 relative'>
                <section className='flex justify-center items-center gap-6'>
                    <Select api={api.categories.searchCategories + "?pageNumber=0&pageSize=30"} optionTitle="name" optionValue="identifier" title="دسته بندی غذا را انتخاب کنید" setState={setSelectedCategory} />
                    <Textbox onChange={(e) => setSearchValue(e.target.value)} placeHolder="جستجو" />
                </section>
                <section className='flex justify-end mt-4'>
                    <button to="/dashboard/addticket" onClick={showAddModal} className="bg-white w-[183px] h-[40px] flex justify-center items-center border border-purple text-purple rounded-full">
                        <img src={svgFileUrl + "plus.svg"} alt="" />
                        <span>افزودن غذا</span>
                    </button>
                </section>
                <section className='min-h-[400px] relative mt-4'>
                    <section className="xl:w-[700px] m-auto text-center text-[14px] relative">
                        <section className="hidden xl:grid  grid-cols-9 gap-1">
                            <section className="w-full">ردیف</section>
                            <section className="w-full col-span-2">نام غذا</section>
                            <section className="w-full">کربوهیدرات</section>
                            <section className="w-full">چربی</section>
                            <section className="w-full">پروتئین</section>
                            <section className="w-full">کالری</section>
                            <section className="w-full col-span-2">عملیات</section>
                        </section>
                        <section className="relative min-h-12">
                            <ComponentLoading loading={loading} />
                            {data.map((item, index) => (
                                <TableRow showEditModal={showEditModal} item={item} />
                            ))}
                        </section>


                    </section>
                </section>

                <section className='flex justify-between items-center mt-4'>
                    <span>تعداد کل رکوردها : {totalElements}</span>
                    <Pagination className="ltr text-center sans" defaultCurrent={1} current={currentPage} pageSize={10} onChange={(value) => setCurrentPage(value)} showSizeChanger={false} total={totalElements} />
                </section>
            </section>
            <Modal width={800} title="افزودن غذا" open={modal} onClose={() => setModal(false)} >
                <AddEditModal rowData={rowData} closeModal={closeModal} />
            </Modal>
        </>

    )
}
