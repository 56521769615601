import React, { useState, useContext, useEffect } from "react";
import { baseFileUrl, imagesFileUrl } from "../../../../config";
import { Link } from "react-router-dom";
import Slider1 from './components/Slider1'
import Slider2 from './components/Slider2'
import Slider3 from './components/Slider3'
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [activeSlide, setActiveSlide] = useState(1)
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    let start = 1
    const myInterval = setInterval(() => {
      if (start === 3) {
        start = 1
      }
      else {
        start = start + 1
      }
      setActiveSlide(start)
    }, 4000);
    return () => {
      clearInterval(myInterval)
    }
  }, [])
  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="xl:hidden h-[725px] mt-[150px] w-full mx-auto mb-[103px] relative border-[3px] border-solid rounded-[50px] border-[#FCD3E6] bg-gradient-to-b from-[#FEEAF300] to-[#FEEAF3]  flex flex-col  justify-start items-center ">
        <img src={baseFileUrl + "/images/bg-section3-mobile.png"} className="h-[370.7px] w-full mt-[-100px] " alt="" />
        <section className="flex flex-col items-center absolute top-2">
          <span className="mb-[19.11px] flex justify-center items-center gap-[8.99px]">
            <span className="text-white bg-[#7639BA] rounded-full h-[29.98px] w-[29.98px] flex justify-center items-center">1</span>خانم هستی یا آقا؟
          </span>
          <section className="flex">
            <img src={baseFileUrl + "/images/boy-mobile.png"} className=" w-[148.6px] " alt="" />
            <img src={baseFileUrl + "/images/girl-mobile.png"} className=" w-[148.6px] " alt="" />
          </section>
          <img src={baseFileUrl + "/images/Frame -slider.png"} className="h-[10.49px] w-[70.13px]  " alt="" />
        </section>
        <section className="flex flex-col px-[18px] mt-[103px] text-[#3E4151]">
          <section className="text-[30px] mt-[30px] font-bold">مرجع تخصصی آموزش حرکات فیتنس و بدنسازی </section>
          <p className="font-normal my-8  leading-[26px]">بهترین و متناسبترین آموزش‌های تخصصی مناسب با اندامت رو با چند کلیک ساده دریافت کن!</p>
          <Link to="/exercise/choose-exercise" className="self-center">
            <button className="bg-purple w-[118px] h-[44px]  rounded-full text-white mb-[40px] font-semibold">بزن بریم</button>
          </Link>
        </section>
      </section>
      {<section className="homeSection3 relative hidden xl:grid grid-cols-2 gap-12 justify-center items-center px-[79px] py-[50px] mt-[150px]">
        <section className="self-start mt-10">
          <section className="text-[55px] font-semibold">مرجع تخصصی آموزش حرکات فیتنس و بدنسازی</section>
          <p className="font-normal text-[22px] mt-5">بهترین و متناسبترین آموزش‌های تخصصی مناسب با اندامت رو با چند کلیک ساده دریافت کن!</p>
          <Link to="/exercise/choose-exercise">
            <button className="bg-purple  w-[250px] h-[60px] text-xl rounded-full text-white mt-10 font-bold">
              بزن بریم
            </button>
          </Link>
        </section>
        <img src={imagesFileUrl + "slider-shape.png"} className="absolute left-[106px] z-0" alt="" />
        <section className="w-[454px] relative">
          <section className="h-[435px]">
            {
              activeSlide === 1 && <Slider1 />
            }
            {
              activeSlide === 2 && <Slider2 />
            }
            {
              activeSlide === 3 && <Slider3 />
            }
          </section>

          <section className="flex justify-center mt-[25px] gap-3 relative">
            <span className={`${activeSlide === 3 ? "w-[55px] bg-[#F02484]" : "w-[14px] bg-[#A6A9BD]"} h-[14px] block rounded-full transition-all`}></span>
            <span className={`${activeSlide === 2 ? "w-[55px] bg-[#F02484]" : "w-[14px] bg-[#A6A9BD]"} h-[14px] block rounded-full transition-all`}></span>
            <span className={`${activeSlide === 1 ? "w-[55px] bg-[#F02484]" : "w-[14px] bg-[#A6A9BD]"} h-[14px] block rounded-full transition-all`}></span>
          </section>
        </section>

      </section>}
    </>
  );
}
