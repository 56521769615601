import React, { useEffect } from "react";
import Calory from "./components/Calory";
import MacroWeightChart from "./components/MacroWeightChart";
import Diet from "./components/Diet";
import Recipe from "./components/Recipe";
import SportTraining from "./components/SportTraining";

import { useBasicInfoStore } from "../../store/dashboard/basicInfo";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const basicInfo = useBasicInfoStore((state) => state.basicInfo);
  const basicInfoLoading = useBasicInfoStore((state) => state.basicInfoLoading);

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    /*  getUserInfo(); */
  }, []);

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      {/* <Loading/> */}
      {/*  <Calender /> */}
      <Calory loading={basicInfoLoading} userInfo={basicInfo} />
      <MacroWeightChart loading={basicInfoLoading} userInfo={basicInfo} />
      <Diet userInfo={basicInfo} />
      <Recipe />
      <SportTraining />
    </>
  );
}
