import { tokenBaseUrl, baseUrl } from "../config";
//////Token/////////////////
const api = {
  auth: {
    getToken: tokenBaseUrl + "auth/realms/bodynessRealm/protocol/openid-connect/token",
    sendMobileNumber: baseUrl + "notification/send-otp",
    verifyOTP: baseUrl + "notification/verify-otp",
    loginOrSignUp: baseUrl + "user-information/login-or-signup",
  },
  user: {
    getUserInfo: baseUrl + "personal-program/find-full-personal-program",
    editUserInfo: baseUrl + "user-information/edit-profile/",
  },
  exercise: {
    searchExercise: baseUrl + "exercise-equipment-and-muscle/search-with-pageable",
  },
  recipe: {
    searchRecipe: baseUrl + "recipe/search-recipes-pageable",
  },
  ticket: {
    calculateTickets: baseUrl + "ticket/calculate-tickets",
    searchTickets: baseUrl + "ticket/search-tickets",
    searchDepartments: baseUrl + "ticket-department/search-departments",
    addTicket: baseUrl + "ticket/add-ticket",
    addMessage: baseUrl + "ticket-message/add-message",
    searchMessages: baseUrl + "ticket-message/search-messages",
  },
  categories: {
    searchCategories: baseUrl + "food-categories/search-categories",
  },
  foodsConsumed: {
    searchCategories: baseUrl + "food-consumed/search-foods-consumed",
  },
  foodsNutrition: {
    searchFoodsNutrition: baseUrl + "food-nutrition-values/search-foods-nutrition",
    addFoodNutrition: baseUrl + "food-nutrition-values/add-food-nutrition",
    editFoodNutrition: baseUrl + "food-nutrition-values/edit-food-nutrition/",
  },
  personalProgram: {
    addDairyToPersonalProgram: baseUrl + "personal-program/add-dairy-to-personal-program",
    getCurrentProgram: baseUrl + "personal-program/find-current-personal-program",
    getCurrentProgramPerDay: baseUrl + "personal-program/find-current-personal-program",
    removeDairy: baseUrl + "personal-program/remove-dairy-from-personal-program",
  },
  water: {
    addWaterToProgram: baseUrl + "personal-program/add-daily-water-to-personal-program",
  },
};

export default api;
