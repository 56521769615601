import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment-jalaali';
import { Link } from 'react-router-dom';

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ item, showEditModal }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="grid grid-cols-3 xl:grid-cols-9 gap-1 mt-[12px] border border-[#E8E7EB] min-h-[78px] xl:min-h-[50px] items-center rounded-[12px] cursor-pointer font-normal  transition-all hover:shadow-[0px_2px_14px_-4px_#5408A929]">
      <section className="w-full">{item?.id}</section>
      <section className="w-full xl:col-span-2">{item?.mainFoodDescription}</section>
      <section className="w-full">{item?.carbohydrateG}</section>

      <section className="w-full">{item?.totalFatG}</section>
      <section className="w-full">{item?.proteinG}</section>
      <section className="w-full">{item?.energyKcal}</section>
      <section className="w-full xl:col-span-2">
        <button onClick={() => showEditModal(item)} className="w-[83px] h-[30px] bg-purple text-white rounded-full">
          ویرایش
        </button>

      </section>
    </section>
  );
}
