import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ open, setOpen }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const navigation = useNavigate()
  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useLayoutEffect(() => {
    let mobileMenuBtn = document.querySelector('.mobileMenuBtn');
    let mobileMenu = document.querySelector('.mobileMenu');
    document.onclick = function (e) {
      if (
        !mobileMenuBtn.contains(e.target) &&
        !mobileMenu.contains(e.target)
      ) {
        setOpen(false);
      }
    };
  }, [])

  // ─── Functions ──────────────────────────────────────────────────────────────────

  const logOut = () => {
    localStorage.clear();
    navigation("/login");
  };
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <aside
      className={`mobileMenu fixed px-8 top-0 ${open ? 'right-0' : 'right-[-70%]'
        } transition-all duration-500 h-full w-[70%] bg-white`}
    >
      <section className="flex justify-start mt-6 items-center">
        {!localStorage.firstName && !localStorage.mobileNumber ? <Link
          to="/login"
          className="bg-purple text-white p-2 px-4 rounded-md"
        >
          ورود / ثبت نام
        </Link> : <span className='block w-full text-center font-bold border-2 border-purple text-purple p-2 rounded-lg'>{`${localStorage.firstName} جان خوش اومدی!`}</span>}
      </section>
      <nav className='flex flex-col justify-start mt-10 mr-[3px] items-start h-[400px]'>
        {
          localStorage.firstName && <Link to="/dashboard" className="text-purple block w-full py-2 border-b border-[#efefef] font-normal text-[18px]">
            داشبورد
          </Link>
        }

        <Link to="/" className="text-purple block w-full py-2 border-b border-[#efefef] font-normal text-[18px]">
          خانه
        </Link>

        <Link to="/exercise-plan" className="text-purple block w-full py-2 border-b border-[#efefef] font-normal text-[18px]">
          برنامه ورزشی
        </Link>
        <Link to="/meal-plan" className="text-purple block w-full py-2 border-b border-[#efefef] font-normal text-[18px]">
          برنامه غذایی
        </Link>
        <Link to="/tools" className="text-purple block w-full py-2 border-b border-[#efefef] font-normal text-[18px]">
          سلامت سنج ها
        </Link>
        <Link to="/exercise/choose-exercise" className="text-purple block w-full py-2 border-b border-[#efefef] font-normal text-[18px]">
          حرکات ورزشی
        </Link>
        <Link to="/recipe" className="text-purple block w-full py-2 border-b border-[#efefef] font-normal text-[18px]">
          رسپی ها
        </Link>
        <Link to="/contact-us" className="text-purple w-full py-2 font-normal  border-b border-[#efefef] text-[18px] ">
          تماس با ما
        </Link>

        {/*   <Link to="" className="text-purple w-full py-2 font-normal  border-b border-[#efefef] text-[18px] ">
          درباره ی ما
        </Link> */}
        {
          localStorage.firstName && <button onClick={logOut} to="/dashboard" className="text-purple text-start block w-full py-2 border-b border-[#efefef] font-normal text-[18px]">
            خروج
          </button>
        }
      </nav>
    </aside>
  );
}
